import { deleteCookie } from './cookies.js';

export function enableGoogleAnalytics(measurementId) {
  const script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.append(script);
}

export function disableGoogleAnalytics() {
  const cookies = document.cookie.match(/((^|;\s+))(.+?)(=)/g);
  if (cookies && cookies.length) {
    cookies
      .map(cookie => cookie.replace(/(^;\s+|=$)/g, ''))
      .filter(cookie => cookie.startsWith('_ga'))
      .forEach(deleteCookie);
  }
}
