const formClass = 'Form--custom';

setTimeout(() => {
  const $formError = document.querySelector(`.${formClass} .errorlist`);

  if($formError) {
    const $field = $formError.closest('.FormField__Errors');
    if ($field) {
      $field.scrollIntoView(false);
    }
  }
}, 300);
