const current = new URL(location.href);

document.querySelectorAll('a').forEach(link => {
  try {
    if (new URL(link).origin !== current.origin) {
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    }
  } catch (error) {}
});
