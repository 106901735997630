import { computePosition, offset, shift, autoUpdate } from "@floating-ui/dom";

const dropdownClass = 'Dropdown';
const dropdownsCache = new Map();

document.addEventListener('click', event => {
  const $targetDropdown = event.target.closest(`.${dropdownClass}`);
  dropdownsCache.forEach((options, $dropdown) => {
    if ($dropdown !== $targetDropdown) {
      $dropdown.classList.remove(options.openClass);
      if (typeof options.stopAutoUpdate === 'function') {
        options.stopAutoUpdate();
        options.stopAutoUpdate = null;
      }
    }
  });
});

const defaultOptions = {
  openClass: 'Dropdown--open',
  contentClass: 'Dropdown__Content'
};

export function initializeDropdown($dropdown, options={}) {
  options = Object.assign({}, defaultOptions, options);
  options.stopAutoUpdate = null;
  dropdownsCache.set($dropdown, options);

  const $dropdownContent = $dropdown.querySelector(`.${options.contentClass}`);

  $dropdown.addEventListener('click', async event => {
    if (event.target.closest(`.${options.contentClass}`)) {
      return;
    }
    $dropdown.classList.toggle(options.openClass);
    const isOpen = $dropdown.classList.contains(options.openClass);

    if (isOpen) {
      positionDropdownContent($dropdown, $dropdownContent);
      options.stopAutoUpdate = autoUpdate($dropdown, $dropdownContent, () => positionDropdownContent($dropdown, $dropdownContent));
    } else if (typeof options.stopAutoUpdate === 'function') {
      options.stopAutoUpdate();
      options.stopAutoUpdate = null;
    }
  });
}

async function positionDropdownContent($dropdown, $dropdownContent) {
  const {x, y} = await computePosition($dropdown, $dropdownContent, {
    placement: 'bottom-start',
    middleware: [
      offset(parseInt($dropdownContent.dataset.dropdownOffset || '0', 10)),
      shift({ padding: 20 })
    ]
  });
  Object.assign($dropdownContent.style, {
    top: `${y}px`,
    left: `${x}px`
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const $dropdowns = document.querySelectorAll(`.${dropdownClass}`);
  $dropdowns.forEach($dropdown => initializeDropdown($dropdown));
});
