import { showPageOverflow, togglePageOverflow } from '../global/base.js';

const siteHeaderClass = 'SiteHeader';
const siteHeaderScrollClass = 'SiteHeader--hasScrolled';
const siteHeaderScrollThreshold = 20;
const siteNavClass = 'SiteNav';
const siteNavOpenClass = 'SiteNav--open';
const siteNavToggleClass = 'SiteNav__Toggle';
const siteNavSheetClass = 'SiteNav__Sheet';
const siteNavLinksClass = 'SiteNav__Links';
const siteNavLinkClass = 'SiteNav__Link';
const siteNavLinkCurrentClass = 'SiteNav__Link--current';

export function initializeSiteHeader() {
  const $header = document.querySelector(`.${siteHeaderClass}`);
  if (!$header) {
    return;
  }

  window.addEventListener('scroll', event => {
    const hasScrolled = document.scrollingElement.scrollTop >= siteHeaderScrollThreshold;
    $header.classList.toggle(siteHeaderScrollClass, hasScrolled);
  });
}

export function initializeSiteNav() {
  const $siteNav = document.querySelector(`.${siteNavClass}`);
  const $siteNavToggle = document.querySelector(`.${siteNavToggleClass}`);
  const $siteNavSheet = document.querySelector(`.${siteNavSheetClass}`);
  if (!$siteNav || !$siteNavToggle || !$siteNavSheet) {
    return;
  }

  $siteNavToggle.addEventListener('click', () => {
    $siteNav.classList.toggle(siteNavOpenClass);
    togglePageOverflow();
  });

  $siteNavSheet.addEventListener('click', event => {
    const $target = event.target.closest(`.${siteNavLinksClass}`);
    if ($target) {
      return;
    }

    $siteNav.classList.remove(siteNavOpenClass);
    showPageOverflow();
  });
}

function getSiteNavLinksRootPath($links) {
  const paths = $links.map($link => new URL($link.href).pathname.split('/').filter(Boolean));
  for (const path of paths) {
    if (path.length === 0) {
      return '/';
    } else if (path.length === 1) {
      return `/${path[0]}/`;
    }
  }
  return '/';
}

export function initializeSiteNavLinks() {
  const currentPath = location.pathname;

  const $links = [...document.querySelectorAll(`.${siteNavLinkClass}`)];
  const rootPath = getSiteNavLinksRootPath([...$links]);
  const currentHash = location.hash;

  for (const $link of $links) {
    $link.classList.remove(siteNavLinkCurrentClass);
  }

  for (const $link of $links) {
    const linkUrl = new URL($link.href);
    const linkPath = linkUrl.pathname;
    const linkHash = linkUrl.hash;

    if (linkPath === rootPath) {
      if (linkPath === currentPath && currentHash === linkHash) {
        $link.classList.add(siteNavLinkCurrentClass);
        break;
      }
    } else if (currentPath.startsWith(linkPath) && currentHash === linkHash) {
      $link.classList.add(siteNavLinkCurrentClass);
      break;
    }
  }
}

export function initializeNavigationMenuShrink() {
  // Scale site logo to fit in sticky header
  const header = document.querySelector('.SiteHeader');
  const headerStyles = getComputedStyle(header);
  const headerOffsetProperty = '--header-offset';
  const headerScrollProgressProperty = '--header-scroll-progress';
  let scrollTopLast = document.scrollingElement.scrollTop;
  let scrollTopStart = scrollTopLast;
  let scrollTopTimer = null;
  const scrollTopTimerDelay = 500;

  if (header) {
    const setScrollTopStart = () => {
      scrollTopStart = scrollTopLast;
      scrollTopTimer = null;
    };

    window.addEventListener('scroll', () => {
      const headerScrollMax = parseInt(headerStyles.getPropertyValue('--header-banner-height'), 10);
      const scrollTop = document.scrollingElement.scrollTop;
      const offset = Math.min(headerScrollMax, scrollTop);
      const progress = offset / headerScrollMax;

      if (!scrollTopTimer) {
        clearTimeout(scrollTopTimer);
        scrollTopTimer = setTimeout(setScrollTopStart, scrollTopTimerDelay);
      }

      let headerOffset = 0;
      if (scrollTopLast > scrollTop) {
        if (scrollTop > headerScrollMax) {
          headerOffset = headerScrollMax;
        } else {
          headerOffset = scrollTopStart > headerScrollMax ? scrollTop : 0;
        }
      }

      document.body.style.setProperty(headerScrollProgressProperty, progress);
      document.body.style.setProperty(headerOffsetProperty, headerOffset);
      scrollTopLast = scrollTop;
    }, { passive: true });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initializeSiteHeader();
  initializeSiteNav();
  initializeSiteNavLinks();
  initializeNavigationMenuShrink();
});

window.addEventListener('hashchange', () => {
  initializeSiteNavLinks();
});
