import { initializeModal } from '../elements/modals.js';
import { setCookie, getCookie, getCookieExpiry } from '../global/cookies.js';
import { enableGoogleAnalytics, disableGoogleAnalytics } from '../global/ga.js';
import { enableFBPixel, disableFBPixel } from '../global/fb-pixel.js';
import { enableGoogleTagManager } from '../global/google-tag-manager.js';
import getState from '../global/state.js';

const cookieConsentBannerClass = 'CookieConsentBanner';
const cookieConsentBannerOpenClass = 'CookieConsentBanner--show';
const cookieConsentPreferencesClass = 'CookieConsentBanner__Action--preferences';
const cookieConsentModalClass = 'Modal--cookieConsent';

const defaultCookiePreferences = {
  'consent-preferences-set': false,
  'consent-necessary': true,
  'consent-analytics': false
};

function getCookiePreferences() {
  const preferences = Object.assign({}, defaultCookiePreferences);
  for (const preference in preferences) {
    const value = getCookie(preference);
    if (value) {
      preferences[preference] = JSON.parse(value);
    }
  }
  return preferences;
}

function setCookiePreferences(preferences) {
  preferences = Object.assign({}, defaultCookiePreferences, preferences);
  const cookieOptions = { expires: getCookieExpiry() };

  for (const preference in preferences) {
    setCookie(preference, preferences[preference], cookieOptions);
  }
  setCookie('consent-preferences-set', true, cookieOptions);
}

function handleCookiePreferences($banner, preferences, options={}) {
  const { initial=false } = options;
  const handlers = {
    'consent-preferences-set': value => $banner.classList.toggle(cookieConsentBannerOpenClass, !value),
    'consent-analytics': value => {
      if (value && getState('googleAnalyticsMeasurementId')) {
        enableGoogleAnalytics(getState('googleAnalyticsMeasurementId'));
      } else {
        disableGoogleAnalytics();
      }
      if (value) {
        enableFBPixel();
        enableGoogleTagManager();
      } else {
        disableFBPixel();
      }
    }
  };

  for (const preference in preferences) {
    if (preference in handlers) {
      handlers[preference](preferences[preference]);
    }
  }

  if (!initial && !preferences['consent-analytics']) {
    window.location.reload();
  }
}

export function initializeCookieConsentBanner($banner) {
  const preferences = getCookiePreferences();
  handleCookiePreferences($banner, preferences, { initial: true });

  const $modal = $banner.querySelector(`.${cookieConsentModalClass}`);
  const modal = $modal ? initializeModal($modal) : null;

  $banner.addEventListener('submit', event => {
    event.preventDefault();
    modal?.close();

    const preferences = {};
    event.target.querySelectorAll('input[type="checkbox"]').forEach($field => {
      preferences[$field.name] = $field.checked;
    });
    preferences['consent-preferences-set'] = true;

    setCookiePreferences(preferences);
    handleCookiePreferences($banner, preferences);
  });

  $banner.addEventListener('click', event => {
    if (event.target.closest(`.${cookieConsentPreferencesClass}`)) {
      modal?.open();
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const $banner = document.querySelector(`.${cookieConsentBannerClass}`);
  if ($banner) {
    initializeCookieConsentBanner($banner);
  }
});
