const hideOverflowClass = 'Page--hideOverflow';

export function togglePageOverflow() {
  document.body.classList.toggle(hideOverflowClass);
}

export function hidePageOverflow() {
  document.body.classList.add(hideOverflowClass);
}

export function showPageOverflow() {
  document.body.classList.remove(hideOverflowClass);
}
