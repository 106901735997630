function openModal($modal, options) {
  $modal.classList.add(options.openClass);
  document.body.classList.add(options.hideOverflowClass);

  if (typeof options.onOpen === 'function') {
    options.onOpen();
  }
}

function closeModal($modal, options) {
  $modal.classList.remove(options.openClass);
  document.body.classList.remove(options.hideOverflowClass);

  if (typeof options.onClose === 'function') {
    options.onClose();
  }
}

const defaultOptions = {
  openClass: 'Modal--open',
  hideOverflowClass: 'Page--hideOverflow',
  backgroundClass: 'Modal__Sheet',
  closeOnClickBackground: false
};

export function initializeModal($modal, options={}) {
  options = Object.assign({}, defaultOptions, options);

  if (options.closeOnClickBackground) {
    $modal.addEventListener('click', event => {
      if (event.target.closest(`.${options.backgroundClass}`)) {
        closeModal($modal, options);
      }
    });
  }

  return {
    open: () => openModal($modal, options),
    close: () => closeModal($modal, options)
  };
}
