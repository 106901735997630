function loadPixelScript(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
      n.callMethod ?
      n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  };
  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  n.queue = []; t = b.createElement(e); t.async = !0;
  t.src = v; s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s)
}

export function enableFBPixel() {
  if (window._fb_pixels) {
    loadPixelScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    for(var i=0; i < window._fb_pixels.length; i++) {
      fbq('init', window._fb_pixels[i])
    }
    window.fbq('track', 'PageView');
  }
}

export function disableFBPixel() {
  if (window.fbq) {
    window.fbq('consent', 'revoke');
  }
}
