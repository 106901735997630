const breadCrumbsClass = 'SiteBreadCrumbs';
const breadCrumbsLinksClass = 'SiteBreadCrumbs__Links';
const breadCrumbsItemClass = 'SiteBreadCrumbs__Item';
const breadCrumbsLinkClass = 'SiteBreadCrumbs__Link';
const breadCrumbsEllipsisTemplateClass = 'SiteBreadCrumbs__EllipsisTemplate';
const breadCrumbsDropdownContentClass = 'SiteBreadCrumbs__Dropdown__Content';
const breadCrumbsDropdownItemClass = 'SiteBreadCrumbs__Dropdown__Item';
const breadCrumbsDropdownItemTemplateClass = 'SiteBreadCrumbs__DropdownItemTemplate';

const hasBreadCrumbs = document.querySelector(`.${breadCrumbsClass}`);
if (hasBreadCrumbs) {
  const $breadCrumbsLinks = document.querySelector(`.${breadCrumbsLinksClass}`);
  if ($breadCrumbsLinks.childElementCount > 3) {
    const dropdownLinks = [...$breadCrumbsLinks.children].slice(1, -1);

    renderNewBreadCrumbs($breadCrumbsLinks);
    renderDropdownLinks(dropdownLinks);
  }
}

function renderNewBreadCrumbs($breadCrumbsLinks) {
  const $breadCrumbsEllipsisTemplate = document.querySelector(`.${breadCrumbsEllipsisTemplateClass}`);

  const $firstLink = $breadCrumbsLinks.firstElementChild;
  const $lastLink = $breadCrumbsLinks.lastElementChild;
  const $ellipsisLink = $breadCrumbsEllipsisTemplate.content.cloneNode(true).firstElementChild;

  $breadCrumbsLinks.innerHTML = '';
  $breadCrumbsLinks.appendChild($firstLink);
  $breadCrumbsLinks.appendChild($ellipsisLink);
  $breadCrumbsLinks.appendChild($lastLink);
}

function renderDropdownLinks(dropdownLinks) {
  const $dropdownContent = document.querySelector(`.${breadCrumbsDropdownContentClass}`);
  const $dropdownItemTemplate = document.querySelector(`.${breadCrumbsDropdownItemTemplateClass}`);

  for (let $dropdownLink of dropdownLinks) {
    $dropdownLink = $dropdownLink.querySelector(`.${breadCrumbsLinkClass}`);
    const $dropdownItem = $dropdownItemTemplate.content.cloneNode(true).firstElementChild;

    $dropdownItem.href = $dropdownLink.href;
    $dropdownItem.textContent = $dropdownLink.textContent.replace('&lt;br&gt;', ' ');
    $dropdownContent.appendChild($dropdownItem);
  }
}
