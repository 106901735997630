const richTextClass = 'RichText';
const richTextEmbedWrapperClass = 'EmbedWrapper';
const aspectRatioMediaClass = 'AspectRatio__Media';
const aspectRatioImageClass = 'AspectRatio__Image';

export function initializeRichText($element) {
  const $iframes = $element.querySelectorAll('iframe');
  $iframes.forEach($iframe => {
    $iframe.classList.add(aspectRatioImageClass);
    $iframe.parentElement.classList.add(richTextEmbedWrapperClass, aspectRatioMediaClass);
  });
}

const $elements = document.querySelectorAll(`.${richTextClass}`);
$elements.forEach($element => initializeRichText($element));
