function loadGTagScript(w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  var j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  d.head.append(j);
}

export function enableGoogleTagManager() {
  if (window._google_tag_manager) {
    loadGTagScript(window, document, 'script', 'dataLayer', window._google_tag_manager);
  }
}
